<template>
  <div class="rankingList">
    <div class="list" style="padding: 45px 36px">
      <div>
        <img
          src="@/assets/ReactHtmlImg/logo.png"
          alt=""
          style="width: 150px; height: 70px"
        />
      </div>
      <div class="titleName">
        <img src="@/assets/ReactHtmlImg/ranking.png" alt="" class="icon" />
      </div>
      <!-- 1 -->
      <div style="margin-bottom: 100px">
        <div class="ranking" style="height: 1000px">
          <div class="top" style="height: 86px">
            <div
              style="
                padding: 0 10px;
                height: 86px;
                font-weight: bold;
                font-family: Alibaba PuHuiTi;
                line-height: 86px;
              "
            >
              <span style="font-size: 58px">TOP10</span>
              <span style="font-size: 29px"
                >{{ queryData.startTime.split("-")[1] }}月{{
                  queryData.startTime.split("-")[2]
                }}日-{{ queryData.endTime.split("-")[1] }}月{{
                  queryData.endTime.split("-")[2]
                }}日</span
              >
            </div>
            <div class="rankingName" style="height: 80px; line-height: 80px">
              <span id="names">排名</span>
              <span id="names">姓名</span>
              <span id="names">积分</span>
            </div>
            <div id="nameFor" v-if="!isShow">
              <ul style="width: 99.99%">
                <li v-for="(num, index) in nameNumber" :key="index">
                  <span v-if="index === 0">
                    <img src="@/assets/ReactHtmlImg/icon5.png" alt="" />
                  </span>
                  <span v-if="index === 1">
                    <img src="@/assets/ReactHtmlImg/icon6.png" alt="" />
                  </span>
                  <span v-if="index === 2">
                    <img src="@/assets/ReactHtmlImg/icon7.png" alt="" />
                  </span>
                  <span style="color: #1f7771" v-if="index > 2">{{
                    index + 1
                  }}</span>
                  <span></span>
                  <span></span>
                </li>
              </ul>
            </div>
            <div v-else id="nameFor">
              <ul style="width: 99.99%">
                <li v-for="(rank, index) in leaderboard.points" :key="index">
                  <span v-if="index === 0">
                    <img src="@/assets/ReactHtmlImg/icon5.png" alt="" />
                  </span>
                  <span v-if="index === 1">
                    <img src="@/assets/ReactHtmlImg/icon6.png" alt="" />
                  </span>
                  <span v-if="index === 2">
                    <img src="@/assets/ReactHtmlImg/icon7.png" alt="" />
                  </span>
                  <span style="color: #1f7771" v-if="index > 2">{{
                    index + 1
                  }}</span>
                  <span>{{ rank.studentName }}</span>
                  <span>{{ rank.totalPoints }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div v-if="leaderboard.comments && leaderboard.comments.length">
        <div class="feedbacks" style="margin: 60px 0 40px">
          <div class="top" style="background: #ffffff">
            <div style="height: 86px; background: #1b4a4a; padding: 0 20px">
              <span>本期课程反馈</span>
              <img src="../../assets/ReactHtmlImg/icon8.png" alt="" />
            </div>
            <div style="margin: 35px 20px">
              <ul>
                <li v-for="(feed, index) in leaderboard.comments" :key="index">
                  <div class="feedbacksName">
                    <div class="left2">{{ index + 1 }}</div>
                    <div
                      class="right2"
                      :class="feed.userName >= 6 ? 'ellipsis' : ''"
                    >
                      {{ feed.userName }}
                    </div>
                  </div>
                  <div class="feedbacksContent">
                    {{ feed.content }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "rankingList",
  data() {
    return {
      queryData: this.$router.history.current.query,
      leaderboard: {},
      isShow: false,
      nameNumber: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      imgList: [
        {
          img: require("@/assets/ReactHtmlImg/icon5.png"),
        },
        {
          img: require("@/assets/ReactHtmlImg/icon6.png"),
        },
        {
          img: require("@/assets/ReactHtmlImg/icon7.png"),
        },
      ],
    };
  },
  created() {
    this.leaderboardImage();
  },
  methods: {
    leaderboardImage() {
      axios({
        method: "get",
        url: "/api/admin/stats/leaderboard/image",
        params: {
          ...this.queryData,
          ignoreSpeciality: this.queryData.specialityId ? 0 : 1,
        },
        headers: { token: this.queryData.token },
      }).then((res) => {
        this.leaderboard = res.data.data;
        this.isShow = res.data.data.points.length > 0;
      });
    },
  },
};
</script>